<template>
	<div>
	<!--	时间选择-->
		<div>
			<el-date-picker
				v-model="recode_time"
				type="daterange"
				range-separator="-"
				start-placeholder="请选择开始时间"
				end-placeholder="请选择结束时间"
				size="small"
				format="YYYY-MM-DD"
				value-format="YYYY-MM-DD"
				@change="getClockRate"
			/>
			<!--同步数据-->
			<el-popconfirm
				confirm-button-text="确认"
				cancel-button-text="取消"
				icon-color="#626AEF"
				:title= this.title
				@confirm="syncClockData"
				@cancel=""
				v-if="GET_BDST === '1'" style="margin-left: 2%"
			>
				<template #reference>
					<el-button  type="warning" size="mini"> 同步数据</el-button>
				</template>
			</el-popconfirm>
			<!--删除数据-->
			<el-popconfirm
				confirm-button-text="确认"
				cancel-button-text="取消"
				icon-color="#626AEF"
				:title= this.title_remove
				@confirm="removeClockData"
				@cancel=""
				v-if="GET_BDST === '1'" style="margin-left: 2%"
			>
				<template #reference>
					<el-button  type="danger" size="mini"> 删除数据</el-button>
				</template>
			</el-popconfirm>

			<a :href="url">
				<el-button type="primary" size="mini" style="margin-left: 2%">导出</el-button>
			</a>
		</div>
	<!--	表格-->
		<div style="margin-top: 1%">
			<vxe-table
				border
				resizable
				:align="allAlign"
				:data="tableData"
				size="small"
				highlight-hover-row
				:span-method="mergeRowMethod"
				:cell-style="cellStyle"
				ref="xTable1"
				:export-config="{}"
			>
				<vxe-column field="bdst" title="工区" width="180"></vxe-column>
				<vxe-column field="carNum" title="车牌号" width="150"></vxe-column>
				<vxe-column field="shouldClock" title="应在线次数" width="150"></vxe-column>
				<vxe-column field="realClock" title="定位在线次数" width="150"></vxe-column>
				<vxe-column field="rate" title="打卡率" width="100"></vxe-column>
			</vxe-table>
		</div>
	</div>
</template>

<script>
import {ElMessage} from "element-plus";
import {mapGetters} from "vuex";
import VXETable from "vxe-table";
import {setNowDate} from "@/utils/time";

export default {
	name: "",
	inject: ['reload'],
	computed: {
		...mapGetters(['GET_BDST']),
		...mapGetters(['GET_USER']),
		...mapGetters(['GET_USERID'])
	},
	data() {
		return {
			recode_time: [],
			tableData: [],
			allAlign: 'center',
			url: '',
			title: '',
			title_remove: ''
		}
	},
	created() {
		let today = new Date()
		this.recode_time[0] = setNowDate(new Date(today.getTime() - 24 * 60 * 60 * 1000))
		this.recode_time[1] = setNowDate(new Date(today.getTime() - 24 * 60 * 60 * 1000))
		//this.url = 'http://localhost:8082/carOnlineRate/leadOutRate?begin_time=' + this.recode_time[0] + '&end_time=' + this.recode_time[1]
		this.url = 'https://show.cr12cz.cn/czsczq/carOnlineRate/leadOutRate?begin_time=' + this.recode_time[0] + '&end_time=' + this.recode_time[1]
		this.getClockRate()
		this.title = '是否同步' + this.recode_time[0] + '日的打卡数据?'
		this.title_remove = '是否删除' + this.recode_time[0] + '日的打卡数据?'
	},
	methods: {
		getClockRate(){
			//this.url = 'http://localhost:8082/carOnlineRate/leadOutRate?begin_time=' + this.recode_time[0] + '&end_time=' + this.recode_time[1]
			this.url = 'https://show.cr12cz.cn/czsczq/carOnlineRate/leadOutRate?begin_time=' + this.recode_time[0] + '&end_time=' + this.recode_time[1]
			this.title = '是否同步' + this.recode_time[0] + '日的打卡数据?'
			this.title_remove = '是否删除' + this.recode_time[0] + '日的打卡数据?'
			this.axios.get('/carOnlineRate/getCarRate', (response) => {
				this.tableData = response.obj
			},{
				begin_time: this.recode_time[0],
				end_time: this.recode_time[1]
			})
		},
		mergeRowMethod ({ row, _rowIndex, column, visibleData }) {
			const fields = ['bdst']
			const cellValue = row[column.property]
			if (cellValue && fields.includes(column.property)) {
				const prevRow = visibleData[_rowIndex - 1]
				let nextRow = visibleData[_rowIndex + 1]
				if (prevRow && prevRow[column.property] === cellValue) {
					return {rowspan: 0, colspan: 0}
				} else {
					let countRowspan = 1
					while (nextRow && nextRow[column.property] === cellValue) {
						nextRow = visibleData[++countRowspan + _rowIndex]
					}
					if (countRowspan > 1) {
						return {rowspan: countRowspan, colspan: 1}
					}
				}
			}
		},
		cellStyle ({ row, rowIndex, column }) {
			if (column.property === 'addup') {
				if (row.addup === 0) {
					return {
						backgroundColor: 'red',
						color: '#ffffff'
					}
				}
			}
			if (column.property === 'start') {
				if (row.start === '/') {
					return {
						backgroundColor: 'green',
						color: '#ffffff'
					}
				}
			}
		},
		syncClockData(){
			this.axios.get('/carGps/getOnlineRate', (response) => {
				if (response.obj){
					ElMessage.success('同步成功!')
					this.getClockRate()
				}
			},{
				time: this.recode_time[0],
				bdst: '1'
			})
		},
		removeClockData(){
			this.axios.get('/carOnlineRate/removeRate', (response) => {
				if (response.obj){
					ElMessage.success('删除成功!')
					this.getClockRate()
				}
			},{
				date: this.recode_time[0]
			})
		}
	}
}
</script>

<style>

</style>