<template>
	<div>
		<!--	选择时间、车牌-->
		<div style="display: flex">
			<div v-if="GET_BDST === '1'">
				<el-tag effect="dark" size="large" style="margin-right: 0.5%"> 选择工区</el-tag>
				<el-select v-model="bdst" class="m-2" placeholder="请选择工区" size="small" style="width: 55%"
				           @change="getOnlineTimeXx">
					<el-option
						v-for="item in bdsts"
						:key="item.value"
						:label="item.label"
						:value="item.value"
					/>
				</el-select>
			</div>
			<div>
				<el-date-picker
					v-model="time"
					type="date"
					placeholder="请选择查询日期"
					format="YYYY-MM-DD"
					value-format="YYYY-MM-DD"
					size="small"
				/>
			</div>
			<div style="margin-left: 2%">
				<el-input v-model="car_number" size="small" placeholder="请输入车牌号" @change="getOnlineTimeXx"/>
			</div>
		</div>
	<!--	数据-->
		<div>
			<el-table :data="tableData" style="width: 50%">
				<el-table-column prop="carNumber" label="车牌" width="180" />
				<el-table-column prop="syncTime" label="时间" width="280" />
				<el-table-column prop="onlineFlag" label="在线情况" />
			</el-table>
		</div>
	</div>
</template>

<script>
import {ElMessage} from "element-plus";
import {mapGetters} from "vuex";
import VXETable from "vxe-table";
import {setNowDate} from "@/utils/time";

export default {
	name: "",
	inject: ['reload'],
	computed: {
		...mapGetters(['GET_BDST']),
		...mapGetters(['GET_USER'])
	},
	data() {
		return {
			time: '',
			car_number: '',
			tableData: [],
			bdst: '51',
			bdsts: [
				{
					value: '51',
					label: '5标一工区',
				},
				{
					value: '52',
					label: '5标二工区',
				},
				{
					value: '53',
					label: '5标三工区',
				},
				{
					value: '54',
					label: '5标四工区',
				},
				{
					value: '55',
					label: '5标五工区',
				},
				{
					value: '57',
					label: '5标七工区',
				}
			]
		}
	},
	created() {
		let today = new Date()
		this.time = setNowDate(new Date(today.getTime() - 24 * 60 * 60 * 1000))
		this.getOnlineTimeXx()
	},
	methods: {
		getOnlineTimeXx() {
			let bdst_last;
			if (this.GET_BDST !== '1'){
				bdst_last = this.GET_BDST
			}else {
				bdst_last = this.bdst
			}
			this.axios.get('/carOnlineRate/getOnlineTime', (response) => {
				this.tableData = response.obj
			}, {
				time: this.time,
				car_number: this.car_number,
				bdst: bdst_last
			})
		}
	}
}
</script>

<style>

</style>