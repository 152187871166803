<template>
	<div>
		<div>
			<Header></Header>
		</div>
		<div id="admindiv1">
			<div style="margin-top: 15px;font-family: '苹方',serif;font-size: 13px;margin-left: 10px">
				<el-breadcrumb separator="/">
					<el-breadcrumb-item style="font-size: 13px" :to="{ path: '/manage' }">首页</el-breadcrumb-item>
					<el-breadcrumb-item style="font-size: 13px">运渣车管理</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
		</div>

		<div id="forms_div2">
			<el-tabs v-model="activeName" tab-position="left" @tab-click="handleClick">
				<el-tab-pane label="上传渣车台账" name="a"><Index v-if="a" /></el-tab-pane>
				<el-tab-pane label="查询渣车台账" name="e"><Get_residue v-if="e" /></el-tab-pane>
				<el-tab-pane label="打卡率查询" name="b"><Get_clock v-if="b" /></el-tab-pane>
				<el-tab-pane label="在线情况查询" name="c"><Select_clock v-if="c" /></el-tab-pane>
			</el-tabs>
		</div>
	</div>
</template>

<script>
import {ElMessage} from "element-plus";
import Header from "@/views/header";
import Save_index from "@/views/material/dbd/save/save_index";
import {mapGetters} from "vuex";
import Index from "@/views/material/waste_residue/index.vue";
import Get_residue from "@/views/material/waste_residue/get_residue.vue";
import Get_clock from "@/views/material/waste_residue/get_clock.vue";
import Select_clock from "@/views/material/waste_residue/select_clock.vue";

export default {
	name: "index",
	components: {Header, Save_index, Index, Get_residue, Get_clock, Select_clock},
	inject: ['reload'],
	computed: {
		...mapGetters(['GET_BDST']),
		...mapGetters(['GET_USER'])
	},
	data() {
		return {
			activeName: 'a',
			a: true,
			e: false,
			b: false,
			c: false
		}
	},
	created() {
		if (!this.$route.params.key){
			this.$router.push("/manage")
		}
	},
	methods: {
		handleClick(tab, event){
			sessionStorage.setItem('dbd_index_tab', tab.props.name)
			if (tab.props.name === 'a'){
				this.a = true
				this.e = false
				this.b = false
				this.c = false
			}
			if (tab.props.name === 'e'){
				this.a = false
				this.e = true
				this.b = false
				this.c = false
			}
			if (tab.props.name === 'b'){
				this.a = false
				this.b = true
				this.e = false
				this.c = false
			}
			if (tab.props.name === 'c'){
				this.a = false
				this.b = false
				this.e = false
				this.c = true
			}
		}
	}
}
</script>

<style scoped>
#admindiv1{
	border-radius: 5px;
	background-color: white;
	display: flex;
	width: 95%;
	height: 40px;
	margin: 10px auto 30px;
	justify-content: space-between;
}

#forms_div2{
	border-radius: 5px;
	margin: 0 auto;
	background-color: white;
	width: 95%;
	padding-top: 20px;
	padding-bottom: 20px;
	min-height: 500px;
}
</style>
