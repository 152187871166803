<template>
	<div>
		<div style="display: grid;grid-template-columns: 1fr 1fr">
			<div v-if="GET_BDST === '1'">
				<el-tag effect="dark" size="large" style="margin-right: 0.5%"> 选择工区 </el-tag>
				<el-select v-model="bdst" class="m-2" placeholder="请选择工区" size="small" style="width: 15%" @change="getShouldOnline()">
					<el-option
						v-for="item in bdsts"
						:key="item.value"
						:label="item.label"
						:value="item.value"
					/>
				</el-select>
			</div>
			<div>
				<el-date-picker
					v-model="recode_time"
					type="daterange"
					range-separator="-"
					start-placeholder="请选择开始时间"
					end-placeholder="请选择结束时间"
					size="small"
					format="YYYY-MM-DD"
					value-format="YYYY-MM-DD"
					@change="getShouldOnline()"
				/>
			</div>
		</div>
		<div>
			<el-table :data="tableData" style="width: 50%">
				<el-table-column prop="syncTime" label="工区" width="180" />
				<el-table-column prop="carNumber" label="车牌号" width="180" />
				<el-table-column prop="recodeDate" label="时间" width="180" />
				<el-table-column prop="location" label="地点" width="180" />
			</el-table>
		</div>
	</div>
</template>

<script>
import {ElMessage} from "element-plus";
import {mapGetters} from "vuex";
import VXETable from "vxe-table";
import {setNowDate, setNowDateNo} from "@/utils/time";

export default {
	name: "",
	inject: ['reload'],
	computed: {
		...mapGetters(['GET_BDST']),
		...mapGetters(['GET_USER'])
	},
	data() {
		return {
			bdst: '',
			bdsts: [
				{
					value: '51',
					label: '5标一工区',
				},
				{
					value: '52',
					label: '5标二工区',
				},
				{
					value: '53',
					label: '5标三工区',
				},
				{
					value: '54',
					label: '5标四工区',
				},
				{
					value: '55',
					label: '5标五工区',
				},
				{
					value: '57',
					label: '5标七工区',
				}
			],
			recode_time: [],
			tableData: []
		}
	},
	created() {
		let today = new Date()
		this.recode_time[0] = setNowDate(new Date(today.getTime() - 24 * 60 * 60 * 1000))
		this.recode_time[1] = setNowDate(new Date(today.getTime() - 24 * 60 * 60 * 1000))
		this.getShouldOnline()
	},
	methods: {
		getShouldOnline(){
			let bdst;
			if (this.bdst === ''){
				bdst = this.GET_BDST
			}else {
				bdst = this.bdst
			}
			this.axios.get('/carGps/getShouldOnline', (response) => {
				this.tableData = response.obj
			},{
				begin_time: this.recode_time[0],
				end_time: this.recode_time[1],
				bdst: bdst
			})
		}
	}
}
</script>

<style>

</style>