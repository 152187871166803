<template>
	<div>
		<div style="width: 50%;margin-left: 35%">
			<!--    上传材料表-->
			<div class="up_material">
				<el-upload
					ref="upload"
					class="upload-demo"
					action="https://www.cr12cz.cn/czscsms/up/upload"
					:auto-upload="false"
					:data="dataup"
					:on-change="imgchange"
					:on-remove="handleRemove"
					:multiple="false"
					:limit="1"
				>
					<template #trigger>
						<el-button type="primary" style="width: 480px">请选择运渣台账</el-button>
					</template>
					<div style="margin-top: 10%">
						<el-button
							style="width: 480px;"
							type="success"
							@click="leadin"
						>上传
						</el-button>
					</div>
				</el-upload>
			</div>
		</div>

	</div>
</template>

<script>
import {ElMessage} from "element-plus";
import {mapGetters} from "vuex";
import Header from "@/views/header.vue";

export default {
	name: "index",
	components: {Header},
	computed: {
		...mapGetters(['GET_BDST'])
	},
	inject: ['reload'],
	data() {
		return {
			fileList: [],
			dataup: {
				file: '',
				userid: ''
			},
		}
	},
	created() {

	},
	methods: {
		imgchange(file) {
			this.fileList.push(file);
		},
		handleRemove(file) {
			for (let i = 0; i < this.fileList.length; i++) {
				if (this.fileList[i].raw.lastModified === file.raw.lastModified) {
					this.fileList.splice(i, 1)
				}
			}
		},
		leadin(){
			this.dataup.file = this.fileList[0].raw;
			this.axios.post('/carGps/importFileCar', (response) => {
				if (response.obj){
					ElMessage({
						message: '导入成功!',
						type: 'success'
					})
					this.reload()
				}
			},{
				file: this.dataup.file,
				bdst: this.GET_BDST
			})
		},
	}
}
</script>

<style scoped>
.up_material{
	display: grid;
	grid-template-rows: 1fr 1fr;
	grid-template-areas: "up up_button";
}
#admindiv1{
	border-radius: 5px;
	background-color: white;
	display: flex;
	width: 95%;
	height: 40px;
	margin: 10px auto 30px;
	justify-content: space-between;
}
</style>
